import { useState } from "react";
import DeleteCard from "../form/DeleteCard";
import PartViewer from "../form/PartViewer";
import AddAndUpdateImmexPedimentoPartnumber from "../form/AddAndUpdateImmexPedimentoPartnumber";

export default function PedimentoPartnumberRow({ row, ind, dashboardRequest }) {
    const [showDelete, setShowDelete] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    return (
        <tr key={ind}>
            <td>
                <button
                    disabled={row.file_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td>{row.partnumber}</td>
            <td>{row.package_qty}</td>
            <td>{row.current_qty}</td>
            <td>{row.unit_cost}</td>
            <td>{row.pedimento}</td>
            <td className="w-28">
                <div className="badge badge-info">
                    {row.import_date.slice(0, 10)}
                </div>
            </td>
            <td className="w-28">
                <div className="badge badge-info">
                    {row.export_date ? row.export_date.slice(0, 10) : "STOCK"}
                </div>
            </td>
            <td>{row.metric_unit}</td>
            <td>{row.description}</td>
            <td>{row.hts}</td>
            <td>{row.location_tag}</td>
            <td>
                <div className="w-20">
                    <button
                        onClick={() => setShowUpdate(true)}
                        className="btn btn-square btn-outline btn-sm btn-info mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                        </svg>
                    </button>
                    <button
                        onClick={() => setShowDelete(true)}
                        className="btn btn-square btn-outline btn-sm btn-error ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                    {
                        showImage ?
                            <PartViewer
                                handleForm={setShowImage}
                                id={row.immex_partnumber_id}
                                url="get_partnumber_image_immex" /> :
                            null
                    }
                    {showUpdate ?
                        <AddAndUpdateImmexPedimentoPartnumber
                            handleForm={setShowUpdate}
                            dashboardRequest={dashboardRequest}
                            title="Update Pedimento Partnumber"
                            startBody={{
                                pedimento_partnumber_id: row.immex_pedimento_partnumber_id,
                                package_qty: row.package_qty,
                                stock_qty: row.current_qty,
                                cost: row.unit_cost
                            }}
                            url="update_pedimento_partnumber" /> : null
                    }
                    <DeleteCard
                        show={showDelete}
                        handleForm={setShowDelete}
                        id={row.immex_pedimento_partnumber_id}
                        dashboardRequest={dashboardRequest}
                        name="PedimentoPartnumber" />
                </div>

            </td>
        </tr>
    );
}