import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import BomTable from "../../components/tables/BomTable";
import IdSelector from "../../components/inputs/IdSelector";
import useFetchSelectorNoStorage from "../../hooks/useFetchSelectorNoStorage";
import AddAndUpdateBomPart from "../../components/form/AddAndUpdateBomPart";

const getBomCost =(data)=>{
    const bomCost = data.reduce((total, row)=>{
        return total + parseFloat(row.bom_cost)
    },0)
    
    return bomCost;
}

const minBomVsStock = (data) =>{
    if(data.length){
        const extractBomVsStock = data.map((row)=>parseInt(row.bom_units));
        return Math.min(...extractBomVsStock);
    }
    else{
        return 0;
    }
}

export default function Bom() {
    const [bomName, setBomName] = useState(1);
    const [showEdit, setShowEdit] = useState(false);

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}get_bom_partnumbers`, { bom_id: bomName })
    const { data: bomsData } = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_bom_selector`);

    useEffect(() => {
        dataTable.handleRequest()
    }, [bomName])
    
    const selectorHandleBom = (e) => {
        console.log(e)
        setBomName(e.target.value)
    }
    return (
        <div className="w-full">
            <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                    <div className="stat-title">Bom Cost</div>
                    <div className="stat-value">${getBomCost(dataTable.data)}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Min Bom vs Stock</div>
                    <div className="stat-value">{minBomVsStock(dataTable.data)}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Parts Numbers</div>
                    <div className="stat-value">{dataTable.data.length}</div>
                </div>
            </div>
            <div className="py-2 flex">
            <IdSelector
                    title='Bom'
                    value={bomName}
                    selectorHandle={selectorHandleBom}
                    data={bomsData}
                    style="input input-bordered border-gray-400 input-sm mr-2"
                />
                <button
                    onClick={() => setShowEdit(true)}
                    className="btn-sm btn btn-outline btn-success mr-2">
                    Add Part
                </button>

                <button
                    onClick={()=>dataTable.handleRequest()}
                    className="btn-sm btn btn-outline btn-info ">
                    Refresh
                </button>
            </div>
            <BomTable
                data={dataTable.data}
                dashboardRequest={dataTable.handleRequest}
                loading={dataTable.loading} />
            {
                showEdit ?
                    <AddAndUpdateBomPart
                        handleForm={setShowEdit}
                        dashboardRequest={dataTable.handleRequest}
                        url="add_partnumber_to_bom"
                        startBody = {{
                            bom_id:bomName,
                            partnumber_id: 1,
                            qty: 0,
                        }} /> : null
            }
        </div>
    )
}