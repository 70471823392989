import useFetchPost from "../../hooks/useFetchPost";
import useFetch from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";

function ClientsList(props) {
	const { changeTab, usersSelected, url } = props;
	console.log(url);
	
	const [isActive, setIsActive] = useState(false);
	const [searchItem, setSearchItem] = useState('')
	const [filteredData, setFilteredData] = useState(null)
	console.log(usersSelected);
	/*const [idActive, setIdActive] = useState(
		usersSelected ? usersSelected.id : ""
	);*/
	var currentArea = "";
	const endpoint = process.env.REACT_APP_API_URL + url;
	const {
		data: areas,
		loading,
		error,
		refetch,
	} = useFetch(endpoint);
	console.log(areas);

	if (loading)
		return <span className="loading loading-spinner loading-lg"></span>;

	if (error) console.error(error);

	const onChangeTab = (id, name) => {
		console.log(usersSelected);
		changeTab({ id: id, name: name });
		setIsActive(current => !current);
		//setIdActive(id);
		// other stuff
	};

	const handleInputChange = (e) => { 
		const searchTerm = e.target.value;
		//setSearchItem(searchTerm)
		setFilteredData(areas.filter(user => user.value.toLowerCase().includes(searchTerm.toLowerCase())));
		//setFilteredData(user.value.toLowerCase().includes(searchTerm.toLowerCase()));
		console.log(filteredData);
		
	}
	

	return (
		<div style={{ maxHeight: 'calc(100vh - 100px)' }} className=" overflow-y-scroll mr-4">
			<label className="input input-bordered flex items-center gap-2 sticky top-0 bg-base-100 z-10">
				<input type="text" className="grow" placeholder="Search" onChange={handleInputChange} />
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 16 16"
					fill="currentColor"
					className="h-4 w-4 opacity-70">
					<path
					fillRule="evenodd"
					d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
					clipRule="evenodd" />
				</svg>
			</label>
			
			<ul className="menu bg-base-100 divide-y divide-dashed flex flex-col">
				

					{filteredData != null ?
						(filteredData.map((client, index) => (
							<li
											className="w-full text-xs"
											onClick={() => onChangeTab(client.id, client.label)}
										>
											{usersSelected?.id && usersSelected.id == client.id ? (
												<a className="active" data-id={client.id}>
													{client.label}
												</a>
											) : (
												<a data-id={client.id}>{client.value}</a>
											)}
										</li>
						)))
					: (areas && areas.map((client, index) => (
						<li
										className="w-full text-xs"
										onClick={() => onChangeTab(client.id, client.label)}
									>
										{usersSelected?.id && usersSelected.id == client.id ? (
											<a className="active" data-id={client.id}>
												{client.label}
											</a>
										) : (
											<a data-id={client.id}>{client.value}</a>
										)}
									</li>
					)))

					}
			</ul>
			</div>
	);
}

export default ClientsList;
