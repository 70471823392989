import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import SelectClients from "../../components/form/selectClients";
import PoTable from "../../components/tables/poTable";
import TanStackTable from "../../components/TanStackTable";
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";

const moment = require("moment");

function PurchaseOrders(props) {
	const { changeTicket, userIdSelected, refresh, tickets } = props;
	
	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "purchaseOrders";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [poIdSelected, setPoIdSelected] = useState(0);
	const [selectedRow, setSelectedRow] = useState([]);

	const [hoursAfterSub, setHoursAfterSub] = useState(0.0);
	const [costAfterSub, setCostAfterSub] = useState(0.0);

	var searchedVal = ""

	var search = ""
	
	var curr = new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);

	const [clientSelected, setClientSelected] = useState();
	const changeTab = index => {
		console.log(index);
		setClientSelected(index.id);
	};

	const [test, setTest] = useState(null);

	const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    var searchedVal = e.target.value.toLowerCase();
	search = searchedVal
	var newData = data.filter((row) =>
		// note that I've incorporated the searchedVal length check here
		!searchedVal.length || row.po
		  .toString()
		  .toLowerCase()
		  .includes(searchedVal.toString().toLowerCase()) 
	  )

	  setData(newData)
    //setInputText(lowerCase);
  };

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	const columns = [
		{
			name: '#',
			selector: row => row.id,
			sortable: true,
		},
		{
			name: 'po',
			selector: row => row.po,
			sortable: true,
		},
		{
			name: 'quote',
			selector: row => row.po,
			sortable: true,
		},
		{
			name: 'Client',
			selector: row => row.client.name,
			sortable: true,
		},
		{
			name: 'pathFile',
			selector: row => row.pathFile,
			sortable: true,
		},
		{
			name: 'hours',
			selector: row => row.hours,
			sortable: true,
		},
		{
			name: 'hoursLeft',
			selector: row => row.hoursLeft,
			sortable: true,
		},
		{
			name: 'cost',
			selector: row => row.cost,
			sortable: true,
		},
		{
			name: 'costLeft',
			selector: row => row.costLeft,
			sortable: true,
		},
		{
			name: 'currency',
			selector: row => row.currency,
			sortable: true,
		},
		{
			name: 'invoice',
			selector: row => row.invoice,
			sortable: true,
		},
		{
			name: 'date',
			selector: row => row.date,
			sortable: true,
		},
		{
			name: 'status',
			selector: row => row.status,
			sortable: true,
		},
	];

	const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

	const handleSearch = (e) => {
		searchedVal = e.target.value.toLowerCase();
		var filteredItems = data.filter(
			item => item.po && item.po.toLowerCase().includes(searchedVal.toLowerCase()),
		);

		console.log("filteresItems ", filteredItems);
		console.log("searchedVal ", searchedVal);
		
	
		setData(filteredItems);
	  };

	  function convertArrayOfObjectsToCSV(array) {
		let result;
	
		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(data[0]);
	
		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;
	
		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
	
				result += item[key];
				
				ctr++;
			});
			result += lineDelimiter;
		});
	
		return result;
	}
	
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
	
		const filename = 'export.csv';
	
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
	
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	
	const Export = ({ onExport }) => <button className="btn join-item btn-neutral m-4" onClick={e => onExport(e.target.value)}>Export</button>;

		const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

		const FilterComponent = ({ filterText, onFilter, onClear }) => (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Name"
					aria-label="Search Input"
					value={filterText}
					onChange={onFilter}
				/>
				<button type="button" onClick={onClear}>
					X
				</button>
			</>
		);
	
		const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	var filteredItems = null;
	if (data) {
		filteredItems = data.filter( 
			item => item.po && item.po.toLowerCase().includes(filterText.toLowerCase()),
		);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	const conditionalRowStyles = [
		/*{
			when: row => row.costLeft > 3000 ,
			style: {
				backgroundColor: 'rgba(63, 195, 128, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.calories >= 300 && row.calories < 400,
			style: {
				backgroundColor: 'rgba(248, 148, 6, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.costLeft == 0,
			style: {
				backgroundColor: 'rgba(242, 38, 19, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'not-allowed',
				},
			},
		},*/
	];

	const handleChange = ({ selectedRows }) => {
		// You can set state or dispatch with something like Redux so we can use the retrieved data
		setSelectedRow(selectedRows);

		


		
		console.log('Selected Rows: ', selectedRows);
		console.log("selected row: ", selectedRow);
		
	  };

	const handleAddPOForm = async event => {
		event.preventDefault();

		//const clientId = clientSelected;
		const poId = event.target.poId.value;
		const reportId = event.target.reportId.value;
		const concept = event.target.concept.value;
		const date = event.target.date.value;
		const hours = event.target.hours.value;
		const cost = event.target.cost.value;
		const hoursBefore = event.target.hoursBefore.value;
		const costBefore = event.target.costBefore.value;
		const hoursAfter = event.target.hoursAfter.value;
		const costAfter = event.target.costAfter.value;
		
		

		if (true) {
			const endpoint = process.env.REACT_APP_API_URL + "purchaseOrdersChanges";
			try {
				const response = await axios.post(
					endpoint,
					{
						poId: poId,
						reportId: reportId,
						concept: concept,
						date: date,
						hours: hours,
						cost: cost,
						hoursBefore: hoursBefore,
						costBefore: costBefore,
						hoursAfter: hoursAfter,
						costAfter: costAfter,
						
						userId: localStorage.getItem("userId"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}

		//console.log(clientId, localStorage.getItem("userId"));

		//const userEmail = event.target.userEmail.value;
	};


	if (loading) 
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	return (
		<div>

			<div
				tabIndex={0}
				className="bg-base-100 w-full p-1 rounded-lg mb-5 collapse collapse-arrow shadow-lg"
			>
				<input type="checkbox" />
				<div className="collapse-title text-xl font-medium flex justify-between items-center">
					<p>Purchase Orders Functions</p>
					<div className="join z-10	">
					<div>
						<div>
						<input className="input input-bordered join-item" placeholder="Search" onChange={handleSearch}/>
						</div>
					</div>
					<div className="indicator">
						<button className="btn join-item btn-neutral">Search</button>
					</div>
				</div>
				</div>
				<div tabIndex={0} className="collapse-content">
					<div className="divider">Add PO</div>
					<form onSubmit={handleAddPOForm}>
						<div className="border-1 grid grid-cols-2 gap-6 p-2">

							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Select Client</span>
								</label>
								<SelectClients changeTab={changeTab} />
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Date</span>
								</label>
								<input
									type="date"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="date"
									defaultValue={date} 
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">PO #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="po"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Quote #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="quote"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Hours</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="hours"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Hours Left</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="hoursLeft"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Cost</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="cost"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Cost Left</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="costLeft"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Currency</span>
								</label>
								<select className="select w-full select-bordered" name="currency">
									<option disabled selected>Select currency</option>
									<option>Dolares</option>
									<option>Pesos</option>
								</select>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Invoice #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="invoice"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Requisitor</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="requisitor"
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Status</span>
								</label>
								<select className="select w-full select-bordered" name="status">
									<option disabled selected>Select status</option>
									<option>Active</option>
									<option>Expired</option>
									<option>Terminated</option>
								</select>
							</div>

						</div>
						<button type="submit" className="btn btn-wide mt-4 mr-2">
							Save PO
						</button>
					</form>

					{selectedRow.length != 0 ?
					<>
						<div className="divider">Add PO Change</div>
						<form onSubmit={handleAddPOForm}>
							<div className="border-1 grid grid-cols-2 gap-6 p-2">

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">PO Id</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="poId"
										defaultValue={selectedRow[0].id}
										disabled
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Report Id</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="reportId"
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Concept</span>
									</label>
									<input
										type="text"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="concept"
									/>
								</div>

								<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Date</span>
								</label>
								<input
									type="date"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="date"
									defaultValue={date} 
								/>
							</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Hours</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="hours"
										onChange={event => {
											console.log(event.target.value);
											
											setHoursAfterSub(parseFloat(selectedRow[0].hoursLeft) - parseFloat(event.target.value));

											console.log(hoursAfterSub);
											
										}}
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Cost</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="cost"
										onChange={event => {
											console.log(event.target.value);
											
											setCostAfterSub(parseFloat(selectedRow[0].costLeft) - parseFloat(event.target.value));

											console.log(costAfterSub);
											
										}}
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Hours Before</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="hoursBefore"
										defaultValue={selectedRow[0].hoursLeft}
										disabled
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Cost Before</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="costBefore"
										defaultValue={selectedRow[0].costLeft}
										disabled
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Hours After</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="hoursAfter"
										value={hoursAfterSub}
										//defaultValue="0"
										disabled
									/>
								</div>

								<div className="form-control w-full ">
									<label className="label">
										<span className="label-text">Cost After</span>
									</label>
									<input
										type="number"
										placeholder="Type here"
										className="input input-bordered w-full "
										name="costAfter"
										value={costAfterSub}
										disabled
									/>
								</div>

								

							</div>
							<button type="submit" className="btn btn-wide mt-4 mr-2">
								Save PO Change
							</button>
						</form>
					</>
					
					: <div className="divider">Add PO Change / *Select PO*</div>

					}
				</div>

					
			</div>
 
			<div className="overflow-x-auto bg-base-100 card shadow-lg">
			{searchedVal != "" ?
				<DataTable
				title="PO Table"
				columns={columns}
				data={filteredItems}
				selectableRows
				onSelectedRowsChange={handleChange}
				expandableRows
				expandableRowsComponent={ExpandedComponent}
				pagination
				actions={actionsMemo}
				paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
				//subHeaderComponent={subHeaderComponentMemo}
			/>
			: data ? <DataTable
			title="PO Table"
			columns={columns}
			data={data}
			selectableRows
			onSelectedRowsChange={handleChange}
			expandableRows
			expandableRowsComponent={ExpandedComponent}
			pagination
			actions={actionsMemo}
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			//subHeaderComponent={subHeaderComponentMemo}
			conditionalRowStyles={conditionalRowStyles}
		/>
		:<></>
			}
			</div>

</div>
	);
}

export default PurchaseOrders;
