
import PartRow from "./PartRow";
export default function PartsTable({ data, dashboardRequest, loading = true }) {
    return (
        <div className="w-full p-4 rounded-lg border-4 border-gray-600">
            <h1 className="text-xl font-bold pb-2">
                Partnumbers
                {loading ?
                    <span className="loading loading-spinner loading-sm ml-2" /> :
                    null
                }
            </h1>
            <div className="overflow-x-auto border-solid border-2 border-black rounded-lg">
                <table className="table table-xs table-zebra">
                    <thead className="border-b-2 border-black">
                        <tr>
                            <th>Id</th>
                            <th>Image</th>
                            <th>PartNumber</th>
                            <th>Description</th>
                            <th>Vendor</th>
                            <th>Manufacturer</th>
                            <th>Category</th>
                            <th>Link</th>
                            <th>Stock Qty</th>
                            <th>Stock Cost</th>
                            <th>Unit Cost</th>
                            <th>Location</th>
                            <th>Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row, ind) =>
                                <PartRow
                                    row={row}
                                    ind={ind}
                                    dashboardRequest={dashboardRequest} />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}