import { useState } from "react";
import AddAndUpdatePartnumber from "../form/AddAndUpdatePartnumber";
import PartViewer from "../form/PartViewer";
import DeleteCard from "../form/DeleteCard";
export default function PartRow({ row, ind, dashboardRequest }) {
    const [showEdit, setShowEdit] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    return (
        <tr key={ind} className="hover">
            <th>{row.partnumber_id}</th>
            <td>
                <button
                    disabled={row.image_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td>{row.partnumber}</td>
            <td>{row.description}</td>
            <td>{row.vendor}</td>
            <td>{row.manufacturer}</td>
            <td>{row.category}</td>
            <td>
                {row.link?
                    <a className="text-blue-600 font-bold" href={row.link} target="_blank" rel="noreferrer">
                        LINK
                    </a>:<p>None</p>
                }


            </td>
            <td>
                <div className={row.stock_qty ? "badge badge-info" : "badge badge-error"}>
                    {row.stock_qty}
                </div>
            </td>
            <td>{row.stock_cost}</td>
            <td>{row.unit_cost}</td>

            <td>{row.location_tag}</td>
            <td className="w-20">{row.created_datetime.slice(0, 10)}</td>
            <td>
                <div className="w-20">

                    <button
                        onClick={() => setShowEdit(true)}
                        className="btn btn-square btn-outline btn-sm btn-info mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                        </svg>
                    </button>
                    <button
                        onClick={() => setShowDelete(true)}
                        className="btn btn-square btn-outline btn-sm btn-error ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                    {
                        showEdit ?
                            <AddAndUpdatePartnumber
                                handleForm={setShowEdit}
                                dashboardRequest={dashboardRequest}
                                startBody={{
                                    partnumber_id: row.partnumber_id,
                                    location_tag: row.location_tag,
                                    partnumber: row.partnumber,
                                    description: row.description,
                                    link: row.link,
                                    category_id: row.category_id,
                                    manufacturer_id: row.manufacturer_id,
                                    vendor_id: row.vendor_id,
                                    cost: row.unit_cost,
                                    stock: row.stock_qty,
                                    files: []
                                }}
                                url="update_partnumber" /> :
                            null
                    }
                    {
                        showImage ?
                            <PartViewer
                                handleForm={setShowImage}
                                id={row.partnumber_id}
                                url="get_partnumber_image" /> :
                            null
                    }
                    <DeleteCard
                        show={showDelete}
                        handleForm={setShowDelete}
                        id={row.partnumber_id}
                        dashboardRequest={dashboardRequest}
                        name="Partnumber" />
                </div>

            </td>
        </tr>
    );
}