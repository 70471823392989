import useFetchPost from "../../hooks/useFetchPost";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";

function UserList(props) {
	const { changeTab, usersSelected, url } = props;
	console.log(url);
	
	const [isActive, setIsActive] = useState(false);
	const [dropdownArr, setDropdownArr] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	console.log(dropdownArr);
	console.log(usersSelected);
	/*const [idActive, setIdActive] = useState(
		usersSelected ? usersSelected.id : ""
	);*/
	var currentArea = "";
	const endpoint = process.env.REACT_APP_API_URL + url;
	const {
		data: areas,
		loading,
		error,
		refetch,
	} = useFetchPost(endpoint, { appName: "schedule" });
	console.log(areas);

	if (loading)
		return <span className="loading loading-spinner loading-lg"></span>;

	if (error) console.error(error);

	const onChangeTab = (id, name, mails) => {
		console.log(usersSelected);
		if (Object.keys(usersSelected).length == 0) {
			console.log("VACIO");
			changeTab({ id: [id], name: [name], mails: mails });
		} else {
			console.log("NO VACIO");
			var arr = usersSelected.id;
			var namesArr = usersSelected.name;
			console.log(arr);
			console.log(namesArr);

			var index = arr.indexOf(id);
			console.log(index);
			if (index !== -1) {
				arr.splice(index, 1);
				namesArr.splice(index, 1);
			} else {
				arr.push(id);
				namesArr.push(name);
			}

			console.log(namesArr);

			changeTab({ id: arr, name: namesArr, mails: mails });
		}
		setIsActive(current => !current);
		//setIdActive(id);
		// other stuff
	};

	/*<>
								<li className="menu-title">
									<span>Monterrey</span>
								</li>
								<li className="hover-bordered">
									<a>Item 1</a>
								</li>
							</> */
	//{users.map((user, index) => (currentArea = user.area))}
	/*className={
											idActive?.includes(user.id)
												? "active"
												: ""
										} */

	/**
										<li className="">
								<span className="text-base text-slate-950 font-bold">
									{area}
								</span>
							</li>
										 */
	return (
		<div className="basis-1/6 mr-2 overflow-y-auto overflow-x-hidden">
			<ul className="menu bg-base-100 w-56 rounded-box">
				{areas &&
					Object.keys(areas.data).map((area, index) => (
						<>
							<li
								className="w-full"
								onClick={() =>
									setDropdownArr(dropdownArr =>
										dropdownArr.map((item, idx) => (idx === index ? !item : item))
									)
								}
							>
								<span className="w-full">
									<b>{area}</b>
									<FontAwesomeIcon
										icon={faChevronDown}
										className={`mt-1 float-right delay-400 duration-500 transition-all ${
											!dropdownArr[index] && "rotate-180"
										}
											`}
									/>
								</span>
							</li>
							<div
								className={`${
									!dropdownArr[index] ? "-top-20 hidden delay-0" : "top-0 delay-900"
								} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								{areas.data[area].map((user, index) => (
									<li
										className="hover-bordered text-xs	"
										onClick={() => {if (user.location) {onChangeTab(user.id, user.name, user.location)} else {onChangeTab(user.id, user.name, areas)}}}
									>
										{usersSelected?.id && usersSelected.id.includes(user.id) ? (
											<a className="active" data-id={user.id}>
												{user.name}
											</a>
										) : (
											<a data-id={user.id}>{user.name}</a>
										)}

										{url == "jigs/list" ?
											<>
											<h2 className="menu-title hidden"></h2>
												<ul className="menu menu-xs">
													{user.jigsItems && user.jigsItems.map((item, index) => (
														<li><a>{item.description}</a></li>
													))}
												</ul>
											</>
											: <></>
										}
									</li>
								))}
							</div>
						</>
					))}
				<li></li>
			</ul>
		</div>
	);
}

export default UserList;
