import axios from "axios";
import UserList from "../../components/ui/userList";
import ScheduleAdmin from "../../components/ui/ScheduleAdmin";
import { useState } from "react";
import VacationList from "../../components/ui/VacationList";
import { format } from "date-fns";

import { toast } from "react-toastify";
import { useEffect } from "react";

function JigsView() {


	const [userSelected, setUserSelected] = useState({
		id: [],
		name: [],
	});
	const [dateSelected, setDateSelected] = useState();
	const [refresh, setRefresh] = useState(false);
	const [presetEvent, setPresetEvent] = useState({
		title: "",
		color: "#0991b2",
	});

	useEffect(() => {
		console.log("ready ");
		
	}, [refresh]);

	const today = new Date();

	var curr = new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);

	// ✅ Get the first day of the current week (Sunday)
	const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 8));

	// ✅ Get the last day of the current week (Saturday)
	const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

	const [emailSubjectSchedule, setEmailSubjectSchedule] = useState(
		"Schedule from: " +
			format(firstDay, "dd/MM/yyyy") +
			" to: " +
			format(lastDay, "dd/MM/yyyy")
	);
	console.log(emailSubjectSchedule);

	console.log("userSelected: ", userSelected);

	const changeTab = index => {
		console.log(index);
		setUserSelected(index);
	};

	const changeDate = index => {
		console.log(index);
		setDateSelected(index.dateStr);
	};

	const handleEventForm = async event => {
		event.preventDefault();

		const title = event.target.eventTitle.value;
		const description = event.target.eventDescription.value;
		const start = event.target.eventStart.value;
		const end = event.target.eventEnd.value;
		const color = event.target.eventColor.value;
		const userId = event.target.userId.value;

		if (title !== "" || start !== "" || end !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "events";
			try {
				const response = await axios.post(
					endpoint,
					{
						title: title,
						description: description,
						start: start,
						end: end,
						allDay: true,
						backgroundColor: color.slice(1),
						borderColor: color.slice(1),
						createdBy: localStorage.getItem("userId"),
						userId: userId,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}

		console.log(title, description, start, end);

		//const userEmail = event.target.userEmail.value;
	};

	const handleAddJig = async jig => {
		jig.preventDefault();

		const row = jig.target.row.value;
		const position = jig.target.position.value;
		const quantity = jig.target.quantity.value;
		const name = jig.target.name.value;
		const description = jig.target.description.value;
		const location = jig.target.location.value;

		if (row !== "" && position !== "" && quantity !== "" && name !== "" && description !== "" && location !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "jigs";
			try {
				const response = await axios.post(
					endpoint,
					{
						row: row,
						position: position,
						quantity: quantity,
						name: name,
						description: description,
						location: location,
						userId: localStorage.getItem("userId"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("Jig Added");
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
	};

	const handleAddJigHistory = async jig => {
		jig.preventDefault();

		const origen = jig.target.origen.value;
		const fechaSalida = jig.target.fechaSalida.value;
		const fechaRetorno = jig.target.fechaRetorno.value;
		const costoIda = jig.target.costoIda.value;
		const costoVuelta = jig.target.costoVuelta.value;
		const destino = jig.target.destino.value;
		const jigId = jig.target.jigId.value;
		

		if (fechaSalida !== "" && fechaRetorno !== "" && costoIda !== "" && costoVuelta !== "" && destino !== "" && jigId !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "jigshistory";
			try {
				const response = await axios.post(
					endpoint,
					{
						origen: origen,
						fechaSalida: fechaSalida,
						fechaRetorno: fechaRetorno,
						costoIda: costoIda,
						costoVuelta: costoVuelta,
						destino: destino,
						jigId: jigId,
						userId: localStorage.getItem("userId"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("Jig Added");
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
	};

	const handleAddJigItem = async jig => {
		jig.preventDefault();

		const quantity = jig.target.quantity.value;
		const description = jig.target.description.value;
		const date = jig.target.date.value;
		const jigId = jig.target.jigId.value;
		

		if (quantity !== "" && description !== "" && date !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "jigsItems";
			try {
				const response = await axios.post(
					endpoint,
					{
						jigId: jigId,
						userId: localStorage.getItem("userId"),
						quantity: quantity,
						description: description,
						date: date,
						
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success("Jig Item Added");
				setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
	};

	return (
		<>
			
			<div
				tabIndex={0}
				className="bg-base-100 w-full p-1 rounded-lg mb-5 collapse collapse-arrow"
			>
				<input type="checkbox" />
				<div className="collapse-title text-xl font-medium">
				<p>Jigs Functions</p>
				</div>
				<div tabIndex={0} className="collapse-content">
					

					<div className="divider">Add Jig</div>
					<form onSubmit={handleAddJig}>
						<div className="border-1 grid grid-cols-2 gap-6 p-2">
							
							<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">EntrepaÑo</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="row"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Posicion</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="position"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Cantidad</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="quantity"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Name</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="name"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Descripcion</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="description"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Locacion</span>
										</label>
										<select
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="location"
										>
											<option value="Aguascalientes">Aguascalientes</option>
											<option value="Baja California">Baja California</option>
											<option value="Bajio">Bajio</option>
											<option value="Chihuahua">Chihuahua</option>
											<option value="Guadalajara">Guadalajara</option>
											<option value="North East">North East</option>
										</select>
									</div>


						</div>
						<button type="submit" className="btn btn-wide mt-4 mr-2">
							SEND
						</button>
					</form>

				{userSelected?.id.length == 1 ? (
					<>
				<div className="divider">Prestamo Jig</div>
					<form onSubmit={handleAddJigHistory}>
						<div className="border-1 grid grid-cols-2 gap-6 p-2">
							
						<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Fecha de Salida</span>
										</label>
										<input
											type="date"
											placeholder="Type here"
											className="input input-bordered w-full "
											value={dateSelected}
											onChange={e => setDateSelected(e.target.value)}
											name="fechaSalida"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Fecha de Retorno</span>
										</label>
										<input
											type="date"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="fechaRetorno"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Costo Ida</span>
										</label>
										<input
											type="number"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="costoIda"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Costo Vuelta</span>
										</label>
										<input
											type="number"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="costoVuelta"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Origen</span>
										</label>
										<select
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="origen"
											value={userSelected.mails}
										>
											<option value="Aguascalientes">Aguascalientes</option>
											<option value="Baja California">Baja California</option>
											<option value="Bajio">Bajio</option>
											<option value="Chihuahua">Chihuahua</option>
											<option value="Guadalajara">Guadalajara</option>
											<option value="North East">North East</option>
										</select>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Destino</span>
										</label>
										<select
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full "
											name="destino"
										>
											<option value="Aguascalientes">Aguascalientes</option>
											<option value="Baja California">Baja California</option>
											<option value="Bajio">Bajio</option>
											<option value="Chihuahua">Chihuahua</option>
											<option value="Guadalajara">Guadalajara</option>
											<option value="North East">North East</option>
										</select>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Jig Id</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full"
											value={userSelected.id.toString()}
											name="jigId"
											disabled
										/>
									</div>


						</div>
						<button type="submit" className="btn btn-wide mt-4 mr-2">
							SEND
						</button>
					</form>

					<div className="divider">Add Jig Item</div>
					<form onSubmit={handleAddJigItem}>
						<div className="border-1 grid grid-cols-2 gap-6 p-2">
							
						<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Quantity</span>
										</label>
										<input
											type="number"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="quantity"
											
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Description</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full"
											name="description"
											
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Date</span>
										</label>
										<input
											type="date"
											placeholder="Type here"
											className="input input-bordered w-full "
											value={date}
											disabled
											//onChange={e => setDateSelected(e.target.value)}
											name="date"
										/>
									</div>

									<div className="form-control w-full ">
										<label className="label">
											<span className="label-text">Jig Id</span>
										</label>
										<input
											type="text"
											placeholder="Type here"
											className="input input-bordered w-full"
											value={userSelected.id.toString()}
											name="jigId"
											disabled
										/>
									</div>
								</div>

						<button type="submit" className="btn btn-wide mt-4 mr-2">
							SEND
						</button>
					</form>
					</>
				) : <></>}
				</div>
			</div>

			<div className="flex flex-row bg-base-100 p-6 rounded-lg">
				<UserList changeTab={changeTab} usersSelected={userSelected} url="jigs/list"/>
				{userSelected?.id ? (
					<div className="w-full">
						<ScheduleAdmin
							userIdSelected={userSelected.id}
							changeDate={changeDate}
							refresh={refresh}
							url="jigshistory/multipleJigs"
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default JigsView;
