import React, { useState, useEffect } from "react";

import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useFetchPost from "../../hooks/useFetchPost";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { toast } from "react-toastify";

function TicketsTable(props) {
	const { changeTicket, clientSelected, refresh, tickets } = props;
	const userId = localStorage.getItem("userId");
	var [refetch, setRefetch] = useState(false);
	var endpoint = "";
	if (tickets == "admin") {
		endpoint = process.env.REACT_APP_API_URL + "tickets";
	} else if (tickets == "user") {
		endpoint = process.env.REACT_APP_API_URL + "ticketsOfUser/" + userId;
	} else if (tickets == "fse") {
		endpoint = process.env.REACT_APP_API_URL + "ticketsOfFSE/" + userId;
	}
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [test, setTest] = useState(null);

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.post(endpoint, {
				userId: userId,
				clientSelected: clientSelected
			}, {
				headers: {
					"Content-Type": "application/json",
				},
				
			})
			.then(response => {
				setData(response.data.data);
				console.log("tickets: ", response.data);
				console.log(data.length);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch, clientSelected]);

	const order = by => {
		const strAscending = [...data].sort((a, b) => (a.by > b.by ? 1 : -1));
		setData(strAscending);
		console.log(strAscending);
	};

	if (loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	const selectTicket = ticketId => {
		console.log(ticketId);
		setTest(true);
		changeTicket(ticketId);
	};

	return (
			<div className="h-full w-full bg-base-100">
				{data !== null && data.length > 0 ? (
					<table className="table h-full">
						{/* head */}
						<thead>
							<tr>
								<th>#</th>
								<th className={tickets === "user" ? "hidden" : ""}>Customer Name</th>
								<th>Subject</th>
								<th>FSE</th>
								<th>Report #</th>
								<th>SaleForce #</th>
								<th
									onClick={() => {
										order("priority");
									}}
								>
									Priority
								</th>
								<th>Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data !== null ? (
								data.map((ticket, i) => (
									<tr>
										<td>{ticket.id}</td>
										<td className={tickets === "user" ? "hidden" : ""}>
											{ticket.client === null ? "" : ticket.client.name}
										</td>
										<td>{ticket.subject === null ? "" : ticket.subject}</td>
										<td>{ticket.fseInfo === null ? "" : ticket.fseInfo.name}</td>
										<td>{ticket.serviceReportNo === null ? "" : ticket.serviceReportNo}</td>
										<td>{ticket.saleforceNo === null ? "" : ticket.saleforceNo}</td>
										<td>{ticket.priority}</td>
										<td>{ticket.date.slice(0, 10)}</td>
										<td>
											<button
												className="btn btn-accent btn-sm"
												data-id={ticket.id}
												onClick={() => {
													selectTicket(ticket.id);
												}}
											>
												open
											</button>
										</td>
									</tr>
								))
							) : (
								<div className="w-full h-full flex justify-center align-middle">
									<div>No data</div>
								</div>
							)}
						</tbody>
						{/* foot
					<tfoot>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Job</th>
							<th>Favorite Color</th>
							<th></th>
						</tr>
					</tfoot>
                    */}
					</table>
				) : (
					<div className="w-full h-full flex justify-center align-middle">
						<div className="p-6">No data</div>
					</div>
				)}
			</div>
	);
}

export default TicketsTable;
